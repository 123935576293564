@import "main";
@import "bootstrap/bootstrap";

#navBarLogo {
  width: 48px;
}

.home-header {
  max-width: 720px;
  
  @extend .p-2, .pb-md-2, .mx-auto, .text-center;
  
  h1 {
    @extend .display-4, .fw-normal;
  }
  
  p {
    @extend .fs-5, .text-muted, .mb-3;
  }
}

.home-content {
  max-width: 960px;
}

.faq-content {
  @extend .home-content;
  
  h2 {
    @extend .display-6, .mb-3;
  }
  
  hr {
    @extend .mt-4;
  }
}

.purchased-header {
  @extend .home-header;
}

.purchased-content {
  @extend .faq-content, .text-center;
}

.warning-container {
  padding: 4px 8px;
  background-color: #f9f2f4;
  border-radius: 8px;
}

.warning-title {
  color: #c7254e;
}

/* Overriding bootstrap's anchor tag styles for our custom self-linked section title elements */
h1.link a,
h1.link a:hover,
h1.link a:focus,
h2.link a,
h2.link a:hover,
h2.link a:focus,
h3.link a,
h3.link a:hover,
h3.link a:focus,
h4.link a,
h4.link a:hover,
h4.link a:focus,
h5.link a,
h5.link a:hover,
h5.link a:focus,
h6.link a,
h6.link a:hover,
h6.link a:focus {
  text-decoration: none;
}

/* Sets up a "hidden" symbol ::before our link that we'll use to help indicate it is a link on hover */
h1.link a::before,
h2.link a::before,
h3.link a::before,
h4.link a::before,
h5.link a::before,
h6.link a::before {
  position: absolute;
  left: -0.5em;
  width: 0.5em;
  content: "\000A7";
  transition: color 0.2s ease;
  text-align: center;
  color: #fff;
  text-shadow: none;
}

/* Show the secret sign! */
h1.link a:hover::before,
h2.link a:hover::before,
h3.link a:hover::before,
h4.link a:hover::before,
h5.link a:hover::before,
h6.link a:hover::before {
  color: #000;
}

.error-content {
  max-width: 720px;
}

